.tribe-info-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.tribe-info-wrapper.tribe-info-wrapper--no-padding {
  margin-left: 0;
}

.tribe-info {
  background-color: black;
  border-radius: 4px;
  text-transform: uppercase;
  color: white;
  padding: 0px 6px;
  font-weight: 700;
  font-size: 0.75rem;
}

.tribe-info-Yase {
  background-color: #ae7519;
}

.tribe-info-Ua {
  background-color: #2e7d32;
}

.tribe-info-Luvu {
  background-color: #1565c0;
}
