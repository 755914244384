.header {
  background-color: #354e68;
  margin: 0;
  display: flex;
  align-items: center;
  padding: 0 1rem;
}

.header .name {
  color: white;
  margin: 0 1rem 0 0;
  font-size: 1.3rem;
  padding: 0.5rem 0;
  text-decoration: none;
}

.header ul {
  margin: 0;
  list-style: none;
  display: flex;
  padding: 0.5rem;
  flex: 1;
}

.header .nav-link {
  border: none;
  padding: 0.25rem 0.5rem;
  text-transform: uppercase;
  border-radius: 4px;
  font-weight: 600;
  color: white;
  background-color: #153045;
  cursor: pointer;
}

.header .nav-link:hover,
.header .nav-link:active,
.header .nav-link:focus {
  background-color: #354e68;
}

.header .nav-link-active {
  background-color: white !important;
  color: #153045 !important;
}

.sign-in-popover {
  padding: 1.5rem;
}
